import React from 'react'
import WebContainer from '../../components/webcontainer'
import SEO from '../../components/SEO'
import {Row, Col} from 'antd'


const Blog = () => {
  
    return (
      <>
      <SEO title="The CalendarBlog has moved" />
      <WebContainer>
        <div style={styles.main}>
          <Row type="flex">
            <Col sm={24} xs={24} style={{ textAlign: 'center' }}>
              <h3>This page has moved <a href="https://help.calendarbridge.com/blog/home">here</a></h3>
            </Col>
            </Row>
            </div>
            </WebContainer>
            </>
    )
}

const styles = {
  main: {
    maxWidth: 800,
    margin: '0 auto',
    padding: '0 20px',
  },
}

export default Blog